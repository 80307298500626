import {
  addAnimationClassOncePartiallyVisible,
  execAfterDomContentLoaded,
  sleep,
} from "../../util";

let elMap: {
  faqBlocks: NodeListOf<HTMLElement>;
};

execAfterDomContentLoaded(afterContentLoaded);

async function afterContentLoaded() {
  elMap = {
    faqBlocks: document.querySelectorAll<HTMLElement>(".faq-block"),
  };

  Array.from(elMap.faqBlocks).forEach((faqBlock) => {
    addAnimationClassOncePartiallyVisible(faqBlock, "animate__flipInY");

    faqBlock.addEventListener("click", async () => {
      faqBlock.classList.add("is-animating");

      if (faqBlock.classList.contains("flip-me")) {
        faqBlock.classList.remove("flip-me");
      } else {
        faqBlock.classList.add("flip-me");
      }

      await sleep(500);
      faqBlock.classList.remove("is-animating");
    });
  });
}
